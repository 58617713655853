import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Sidebar from '../components/sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import {
  DesktopNavbar,
  MobileDashboardNavbar,
} from '../components/navbar/Navbar';

export const PrivateRoute = () => {
  const { pathname } = useLocation();

  const userInfo = JSON.parse(secureLocalStorage.getItem(`user-info`))
    ? JSON.parse(secureLocalStorage.getItem(`user-info`))
    : ``;

  return userInfo ? (
    <main className='dashboard-page'>
      <Sidebar currentPage={pathname} />
      <div className='navbar-content'>
        <DesktopNavbar />
        <MobileDashboardNavbar currentPage={pathname} />
        <Outlet />
      </div>
    </main>
  ) : (
    <Navigate to='/' />
  );
};
