'use client';
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ImageZoom = ({ imageSrc, alt }) => {
  return (
    <Zoom>
      <img src={imageSrc} alt={alt} />
    </Zoom>
  );
};

export default ImageZoom;
