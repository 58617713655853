import {
  DeleteUserForm,
  SendEmailForm,
  UpdateFundsInfoForm,
  UpdateNotificationForm,
  UpdatePersonalInfoForm,
  UpdateUserVerificationStatusForm,
  UpdateUserWithdrawalStatusForm,
} from '../../../components/forms/Forms';
import React, { useEffect, useState } from 'react';
import avatar from '../../../assets/images/user-avatar.png';
import { FaChevronLeft } from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { PageSpinner } from '../../../components/spinner/Spinner';
import { config } from '../../../Constants';

const UserPage = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading(false);
        setData(data.user);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const {
    fullname,
    country,
    email,
    _id,
    phone,
    profits,
    deposits,
    pendingDeposits,
    bonus,
    notification,
    maritalStatus,
    address,
    userCanWithdraw,
    isVerified,
    signalStrength,
  } = data;

  return (
    <main className='content user-page'>
      <div className='title-btn'>
        <Link to='/dashboard/users'>
          <FaChevronLeft />
        </Link>
        <h1 className='title'>User Account</h1>
      </div>

      {loading ? (
        <PageSpinner />
      ) : (
        <div className='blocks-cont'>
          <div className='brief-block'>
            <div className='img'>
              <img src={avatar} alt='' />
            </div>
            <h2>{fullname}</h2>
            <h4>{email}</h4>
            <p>{country}</p>
            <p className={isVerified ? `verified` : `unverified`}>
              {isVerified ? `Verified` : `Unverified`}
            </p>
          </div>

          <div className='grid'>
            {/* funds information */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Funds Information</h2>
                <h4 className='sub-title'>Update Info</h4>
              </div>
              <hr />
              <UpdateFundsInfoForm
                userId={_id}
                profits={profits}
                bonus={bonus}
                deposits={deposits}
                pendingDeposits={pendingDeposits}
                signalStrength={signalStrength}
              />
            </div>

            {/* notification */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Notification</h2>
                <h4 className='sub-title'>Update</h4>
              </div>
              <hr />
              <UpdateNotificationForm
                notification={notification}
                userId={_id}
              />
            </div>

            {/* email */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Email</h2>
                <h4 className='sub-title'>Send email</h4>
              </div>
              <hr />
              <SendEmailForm email={email} />
            </div>

            {/* personal information */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Personal Information</h2>
                <h4 className='sub-title'>Update Info</h4>
              </div>
              <hr />
              <UpdatePersonalInfoForm
                email={email}
                fullname={fullname}
                userId={_id}
                phone={phone}
                country={country}
                maritalStatus={maritalStatus}
                address={address}
              />
            </div>

            {/* verification status */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Verify User</h2>
                <h4 className='sub-title'>Update verification status</h4>
              </div>
              <hr />
              <UpdateUserVerificationStatusForm
                userId={_id}
                isVerified={isVerified}
              />
            </div>

            {/* withdrawal status */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Can this user withdraw?</h2>
                <h4 className='sub-title'>Update withdrawal permission</h4>
              </div>
              <hr />
              <UpdateUserWithdrawalStatusForm
                userId={_id}
                userCanWithdraw={userCanWithdraw}
              />
            </div>

            {/* delete user */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title danger'>Danger Zone</h2>
                <h4 className='sub-title'>Delete this user</h4>
              </div>
              <hr />
              <DeleteUserForm id={_id} />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default UserPage;
