import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './context/context';
import { MantineProvider } from '@mantine/core';

import './scss/styles.scss';
import 'swiper/css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <MantineProvider>
          <App />
        </MantineProvider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);
