import { WithdrawalsTable } from '../../components/tables/Tables';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { PageSpinner } from '../../components/spinner/Spinner';
import NothingToSeeHere from '../../components/nothing-to-see-here/NothingToSeeHere';
import { config } from '../../Constants';

const Withdrawals = () => {
  const [data, setData] = useState({});
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const [loading, setLoading] = useState(true);
  const baseURL = config.baseURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/withdrawals/admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data);
        setLoading(false);
        setData(data.withdrawals);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content withdrawals-page'>
      <div className='title'>
        <h1 className='title'>Withdrawals</h1>
      </div>
      {loading ? (
        <PageSpinner />
      ) : !loading && data.length > 0 ? (
        <WithdrawalsTable data={data} />
      ) : (
        <NothingToSeeHere />
      )}
    </main>
  );
};

export default Withdrawals;
