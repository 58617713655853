import React from 'react';
import spinner from '../../assets/images/white-spinner.svg';
import purpleSpinner from '../../assets/images/purple-spinner.svg';

export const Spinner = () => {
  return <img alt='' src={spinner} />;
};

export const SpinnerPurple = () => {
  return <img alt='' src={purpleSpinner} />;
};

export const PageSpinner = () => {
  return (
    <div className='page-loading'>
      <img src={purpleSpinner} alt='' />
    </div>
  );
};
