const prod = {
  baseURL: `https://api.leofxtrade.org/api/v1`,
  apiURL: `https://api.leofxtrade.org`,
};

const dev = {
  baseURL: `https://api.leofxtrade.org/api/v1`,
  apiURL: `https://api.leofxtrade.org`,
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;
