import { SendBulkEmailsForm } from '../../components/forms/Forms';
import React from 'react';

const BulkEmails = () => {
  return (
    <main className='content bulk-emails-page'>
      <div className='title'>
        <h1 className='title'>Bulk Emails</h1>
      </div>

      <div className='block-cont'>
        {/* email */}
        <div className='block'>
          <div className='title-block'>
            <h2 className='title'>Email</h2>
            <h4 className='sub-title'>Send Emails To All Registered Users</h4>
          </div>
          <hr />
          <SendBulkEmailsForm />
        </div>
      </div>
    </main>
  );
};

export default BulkEmails;
