'use client';
import { BiLogOut } from 'react-icons/bi';
import { FaBars, FaTimes } from 'react-icons/fa';
import companyLogo from '../../assets/images/company-logo.svg';
import { useGlobalContext } from '../../context/context';
import { sidebarLinks } from '../sidebar/data';
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export const DesktopNavbar = () => {
  const navigate = useNavigate();

  return (
    <nav className='desktop-nav'>
      <div className='profile-logout'>
        <button
          className='red'
          onClick={() => {
            secureLocalStorage.removeItem(`user-info`);
            navigate(`/`);
          }}
        >
          Logout <BiLogOut />
        </button>
      </div>
    </nav>
  );
};

export const MobileDashboardNavbar = ({ currentPage }) => {
  const { showDashboardNavbar, setShowDashboardNavbar } = useGlobalContext();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    setShowDashboardNavbar(false);
  };

  return (
    <nav className='mobile-nav'>
      <div className='logo'>
        <Link to='/dashboard/overview'>
          <img src={companyLogo} alt='LeoFx Trade' />
        </Link>
      </div>
      <div className={`links ${showDashboardNavbar && `active`}`}>
        <ul>
          {sidebarLinks.map((link, linkIndex) => {
            const { icon, name, link: pageLink } = link;
            return (
              <li
                className={
                  currentPage.toLocaleLowerCase() === pageLink ? `active` : ``
                }
                key={linkIndex}
                onClick={handleLinkClick}
              >
                <Link to={pageLink}>
                  {icon} {name}
                </Link>
              </li>
            );
          })}
          <li className='logout' onClick={handleLinkClick}>
            <button
              className='red'
              onClick={() => {
                secureLocalStorage.removeItem(`user-info`);
                navigate(`/`);
              }}
            >
              Logout <BiLogOut />
            </button>
          </li>
        </ul>
        <div
          className='close-btn'
          onClick={() => setShowDashboardNavbar(false)}
        >
          <FaTimes />
        </div>
      </div>
      <div
        className={`hamburger `}
        onClick={() => setShowDashboardNavbar(true)}
      >
        <FaBars />
      </div>
    </nav>
  );
};
