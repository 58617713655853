'use client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import axios from 'axios';
import { config } from '../../Constants';
import toast from 'react-hot-toast';
import { SpinnerPurple } from '../spinner/Spinner';
import secureLocalStorage from 'react-secure-storage';

export const MessagesTable = ({ data }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [currentMsg, setCurrentMsg] = useState({});
  const baseURL = config.baseURL;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const deleteMessage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/contact-us/${currentMsg._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      close();
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.success(error.response.data.msg);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className='messages-table'>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell align='right'>EMAIL</TableCell>
              <TableCell align='right'>SENT</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((info) => {
              const { createdAt, email, name, _id: id } = info;

              return (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{ cursor: `pointer` }}
                  key={id}
                  className='user-row'
                  onClick={() => {
                    setCurrentMsg(data.filter((msg) => msg._id === id)[0]);
                    open();
                  }}
                >
                  <TableCell component='th' scope='row' className='name'>
                    {name}
                  </TableCell>
                  <TableCell align='right'>{email}</TableCell>
                  <TableCell align='right'>
                    {moment(createdAt).format(`LLL`)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        opened={opened}
        onClose={close}
        centered
        size='lg'
        className='message-modal'
        overlayProps={{
          backgroundOpacity: 0.55,
        }}
      >
        <div className='title'>
          <h2>
            Message sent by <span>{currentMsg.name}</span>
          </h2>
          <h3>{currentMsg.email}</h3>
        </div>

        <div className='info'>
          <p>{currentMsg.message}</p>
        </div>

        <div className='del-btn'>
          {loading ? (
            <SpinnerPurple />
          ) : (
            <MdDeleteForever onClick={deleteMessage} />
          )}
        </div>
      </Modal>
    </>
  );
};

export const UsersTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className='users-table'>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell align='right'>EMAIL</TableCell>
            <TableCell align='right'>PHONE</TableCell>
            <TableCell align='right'>SIGNED UP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user) => {
            const { _id, createdAt, email, fullname, phone } = user;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ cursor: `pointer` }}
                key={_id}
                className='user-row'
                onClick={() => navigate(`/dashboard/users/${_id}`)}
              >
                <TableCell component='th' scope='row'>
                  {fullname}
                </TableCell>
                <TableCell align='right'>{email}</TableCell>
                <TableCell align='right'>{phone}</TableCell>
                <TableCell align='right'>
                  {moment(createdAt).format(`LLL`)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const DepositsTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className='deposits-table'>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell align='right'>DEPOSIT AMOUNT</TableCell>
            <TableCell align='right'>TIME</TableCell>
            <TableCell align='right'>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((deposit) => {
            const { _id, createdAt, userName, status, amount } = deposit;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ cursor: `pointer` }}
                key={_id}
                className='deposit-row'
                onClick={() => navigate(`/dashboard/deposits/${_id}`)}
              >
                <TableCell component='th' scope='row' className='name'>
                  {userName}
                </TableCell>
                <TableCell align='right'>${amount}</TableCell>
                <TableCell align='right'>
                  {moment(createdAt).format(`LLL`)}
                </TableCell>
                <TableCell align='right' className='status'>
                  <span className={status}>{status}</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const WithdrawalsTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className='withdrawal-table'>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell align='right'>WITHDRAWAL AMOUNT</TableCell>
            <TableCell align='right'>TIME</TableCell>
            <TableCell align='right'>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((withdrawal) => {
            const { _id, createdAt, userName, status, amount } = withdrawal;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ cursor: `pointer` }}
                key={_id}
                className='withdrawal-row'
                onClick={() => navigate(`/dashboard/withdrawals/${_id}`)}
              >
                <TableCell component='th' scope='row' className='name'>
                  {userName}
                </TableCell>
                <TableCell align='right'>${amount}</TableCell>
                <TableCell align='right'>
                  {moment(createdAt).format(`LLL`)}
                </TableCell>
                <TableCell align='right' className='status'>
                  <span className={status}>{status}</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const KYCsTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className='kycs-table'>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell align='right'>DOCUMENT NAME</TableCell>
            <TableCell align='right'>TIME SENT</TableCell>
            <TableCell align='right'>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((kyc) => {
            const { _id, createdAt, userName, status, document } = kyc;
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ cursor: `pointer` }}
                key={_id}
                className='kyc-row'
                onClick={() => navigate(`/dashboard/kyc/${_id}`)}
              >
                <TableCell component='th' scope='row' className='name'>
                  {userName}
                </TableCell>
                <TableCell align='right' className='document'>
                  {document}
                </TableCell>
                <TableCell align='right'>
                  {moment(createdAt).format(`LLL`)}
                </TableCell>
                <TableCell align='right' className='status'>
                  <span className={status}>{status}</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
