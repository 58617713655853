import React, { useEffect, useState } from 'react';
import avatar from '../../../assets/images/user-avatar.png';
import moment from 'moment';
import { UpdateWithdrawalStatusForm } from '../../../components/forms/Forms';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { PageSpinner } from '../../../components/spinner/Spinner';
import { config } from '../../../Constants';

const Withdrawal = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const baseURL = config.baseURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/withdrawals/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data);
        setData(data);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const {
    userName,
    amount,
    type,
    status,
    ref,
    address,
    createdAt,
    _id,
    email,
    network,
  } = data;

  return (
    <main className='content withdrawal-page'>
      <div className='title-btn'>
        <Link to='/dashboard/withdrawals'>
          <FaChevronLeft />
        </Link>
        <h1 className='title'>User Withdrawal</h1>
      </div>

      {data ? (
        <div className='blocks-cont'>
          <div className='brief-block'>
            <div className='img'>
              <img src={avatar} alt='' />
            </div>
            <h2>{userName}</h2>
            <h4>{email}</h4>
          </div>

          <div className='grid'>
            {/* transaction details */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Transaction Details</h2>
              </div>
              <hr />

              <form>
                {/* amount & crypto type */}
                <div className='form-row'>
                  <div className='form-control'>
                    <label htmlFor='amount'>Withdrawal Amount</label>
                    <input type='text' value={`$${amount}`} disabled />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='amount'>Crypto Type</label>
                    <input type='text' value={type} disabled />
                  </div>
                </div>

                {/* crypto network & address */}
                <div className='form-row'>
                  <div className='form-control'>
                    <label htmlFor='amount'>Crypto Network</label>
                    <input type='text' value={network} disabled />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='amount'>Withdrawal Address</label>
                    <input type='text' value={address} disabled />
                  </div>
                </div>

                {/* transaction date & reference  */}
                <div className='form-row'>
                  <div className='form-control'>
                    <label htmlFor='amount'>Transaction Date</label>
                    <input
                      type='text'
                      value={moment(createdAt).format(`LLL`)}
                      disabled
                    />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='amount'>Transaction Ref.</label>
                    <input type='text' value={ref} disabled />
                  </div>
                </div>
              </form>
            </div>

            {/* transaction status */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Transaction Status</h2>
                <h4 className='sub-title'>Update Status</h4>
              </div>
              <hr />
              <UpdateWithdrawalStatusForm status={status} withdrawalId={_id} />
            </div>
          </div>
        </div>
      ) : (
        <PageSpinner />
      )}
    </main>
  );
};

export default Withdrawal;
