import React, { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa6';
import { HiCurrencyDollar } from 'react-icons/hi2';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { config } from '../../Constants';

const Overview = () => {
  const [data, setData] = useState({});
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const baseURL = config.baseURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/overview-stats`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(data);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content overview-page'>
      <h1 className='title'>Overview</h1>

      <div className='cards'>
        <div className='card'>
          <div className='info'>
            <h4>Users</h4>
            <h2>{data ? data.users : 0}</h2>
          </div>
          <div className='icon customers'>
            <FaUsers />
          </div>
        </div>
        <div className='card'>
          <div className='info'>
            <h4>Total Deposits</h4>
            <h2>{data ? data.deposits : 0}</h2>
          </div>
          <div className='icon deposits'>
            <HiCurrencyDollar />
          </div>
        </div>
        <div className='card'>
          <div className='info'>
            <h4>Total Withdrawals</h4>
            <h2>{data ? data.withdrawals : 0}</h2>
          </div>
          <div className='icon withdrawals'>
            <HiCurrencyDollar />
          </div>
        </div>
        <div className='card'>
          <div className='info'>
            <h4>Messages</h4>
            <h2>{data ? data.contactMessages : 0}</h2>
          </div>
          <div className='icon messages'>
            <FaUsers />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Overview;
