import React, { useEffect, useState } from 'react';
import avatar from '../../../assets/images/user-avatar.png';
import moment from 'moment';
import { UpdateKYCStatusForm } from '../../../components/forms/Forms';
import ImageZoom from '../../../components/image-zoom/ImageZoom';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { PageSpinner } from '../../../components/spinner/Spinner';
import { config } from '../../../Constants';

const UserKYC = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const baseURL = config.baseURL;
  const apiURL = config.apiURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/kyc/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(data);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const { userName, status, image, document, createdAt, _id } = data;

  return (
    <main className='content user-kyc-page'>
      <div className='title-btn'>
        <Link to='/dashboard/kyc'>
          <FaChevronLeft />
        </Link>
        <h1 className='title'>User KYC</h1>
      </div>

      {data ? (
        <div className='blocks-cont'>
          <div className='brief-block'>
            <div className='img'>
              <img src={avatar} alt='' />
            </div>
            <h2>{userName}</h2>
          </div>

          <div className='grid'>
            {/* kyc details */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>KYC Details</h2>
              </div>
              <hr />

              <form>
                {/* document submitted & date submitted */}
                <div className='form-row'>
                  <div className='form-control'>
                    <label htmlFor='amount'>Document Submitted</label>
                    <input type='text' value={document} disabled />
                  </div>
                  <div className='form-control'>
                    <label htmlFor='amount'>Date Submitted</label>
                    <input
                      type='text'
                      value={moment(createdAt).format(`LLL`)}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* document uploaded */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>Document Uploaded</h2>
              </div>
              <hr />

              <div className='img'>
                <ImageZoom
                  alt='document uploaded'
                  height={300}
                  width={300}
                  imageSrc={`${apiURL}${image}`}
                />
              </div>
            </div>

            {/* transaction status */}
            <div className='block'>
              <div className='title-block'>
                <h2 className='title'>KYC Status</h2>
                <h4 className='sub-title'>Update Status</h4>
              </div>
              <hr />
              <UpdateKYCStatusForm status={status} kycId={_id} />
            </div>
          </div>
        </div>
      ) : (
        <PageSpinner />
      )}
    </main>
  );
};

export default UserKYC;
