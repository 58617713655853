import React from 'react';

const NothingToSeeHere = () => {
  return (
    <section className='nothing-to-see-here'>
      <h2>Nothing to see here... Yet</h2>
    </section>
  );
};

export default NothingToSeeHere;
