import { useEffect } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import {
  BulkEmails,
  Deposit,
  Deposits,
  Home,
  KYCs,
  Messages,
  Overview,
  Settings,
  UserKYC,
  UserPage,
  Users,
  Wallets,
  Withdrawal,
  Withdrawals,
} from './pages';

import { PrivateRoute } from './pages/PrivateRoute';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />

        {/* dashboard pages */}
        <Route element={<PrivateRoute />}>
          <Route path='/dashboard/overview' element={<Overview />} />
          <Route path='/dashboard/settings' element={<Settings />} />
          <Route path='/dashboard/wallets' element={<Wallets />} />
          <Route path='/dashboard/messages' element={<Messages />} />
          <Route path='/dashboard/users' element={<Users />} />
          <Route path='/dashboard/users/:id' element={<UserPage />} />
          <Route path='/dashboard/deposits' element={<Deposits />} />
          <Route path='/dashboard/deposits/:id' element={<Deposit />} />
          <Route path='/dashboard/withdrawals' element={<Withdrawals />} />
          <Route path='/dashboard/withdrawals/:id' element={<Withdrawal />} />
          <Route path='/dashboard/kyc' element={<KYCs />} />
          <Route path='/dashboard/kyc/:id' element={<UserKYC />} />
          <Route path='/dashboard/bulk-emails' element={<BulkEmails />} />
        </Route>
      </Routes>

      <Toaster position='top-center' />
    </>
  );
}

export default App;
