import { AiFillHome } from 'react-icons/ai';
import { IoSettings } from 'react-icons/io5';
import {
  FaMessage,
  FaUsers,
  FaCircleDollarToSlot,
  FaWallet,
  FaPassport,
  FaMoneyBillTransfer,
} from 'react-icons/fa6';
import { FaMailBulk } from 'react-icons/fa';

export const sidebarLinks = [
  {
    name: `Overview`,
    icon: <AiFillHome />,
    index: 1,
    link: `/dashboard/overview`,
  },
  {
    name: `Users`,
    icon: <FaUsers />,
    link: `/dashboard/users`,
  },
  {
    name: `Deposits`,
    icon: <FaCircleDollarToSlot />,
    link: `/dashboard/deposits`,
  },
  {
    name: `Withdrawals`,
    icon: <FaMoneyBillTransfer />,
    link: `/dashboard/withdrawals`,
  },
  {
    name: `KYCs`,
    icon: <FaPassport />,
    link: `/dashboard/kyc`,
  },
  {
    name: `Messages`,
    icon: <FaMessage />,
    link: `/dashboard/messages`,
  },
  {
    name: `Bulk Emails`,
    icon: <FaMailBulk />,
    link: `/dashboard/bulk-emails`,
  },
  {
    name: `Wallets`,
    icon: <FaWallet />,
    link: `/dashboard/wallets`,
  },
  {
    name: `Settings`,
    icon: <IoSettings />,
    link: `/dashboard/settings`,
  },
];
