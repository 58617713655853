import {
  UpdatePasswordForm,
  UpdateWhatsappNumberForm,
} from '../../components/forms/Forms';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { config } from '../../Constants';

const Settings = () => {
  const [whatsappData, setWhatsappData] = useState({});
  const baseURL = config.baseURL;

  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/whatsapp`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWhatsappData(data[0]);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='content settings-page'>
      <h1 className='title'>Settings</h1>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>Password</h2>
          <h4 className='sub-title'>Update Password</h4>
        </div>
        <hr />
        <UpdatePasswordForm />
      </div>

      <div className='block'>
        <div className='title-block'>
          <h2 className='title'>WhatsApp Number</h2>
          <h4 className='sub-title'>Update WhatsApp number</h4>
        </div>
        <hr />
        <UpdateWhatsappNumberForm data={whatsappData} />
      </div>
    </main>
  );
};

export default Settings;
